import React, { useEffect } from "react";
import { Grid, Button } from "@mui/material";
import BlackArrow from "../assets/img/BlackRight.png";

const MobileAd = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);
  return (
    <Grid
      sx={{ height: "100vh", justifyContent: "center", alignItems: "center" }}
    >
      <Button
        sx={{
          color: "black",
          fontSize: 16,
          fontWeight: 400,
          fontFamily: "Mulish",
          gap: 2,
          position:'absolute',
          top:'35px',
          right:"35px"
        }}
      >
        Continue to Abbreviations {<img src={BlackArrow} />}
      </Button>
      <ins
        className="adsbygoogle"
        style={{ display: "block", width:"100%" }}
        data-ad-client="ca-pub-5555692085574234"
        data-ad-slot="8577496386"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </Grid>
  );
};

export default MobileAd;
