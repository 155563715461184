import React, {forwardRef} from "react";
import { Dialog, dialogClasses, Typography, Link, styled, Slide, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomDialog = styled(Dialog)(({ theme }) => ({
    [`& .${dialogClasses.paper}`]: {
        borderRadius: '18px',
        width: 430,
        height: 232,
    },
}));

const Trasition = forwardRef(function Trasition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function  SettingDialog({settingOpen, setSettingOpen}) {
    return (
        <CustomDialog
            onClose={() => setSettingOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={settingOpen}
            sx={{ borderRadius: '18px' }}
            TransitionComponent={Trasition}
        >
            <Grid container item sx={{ rowGap: '40px', paddingTop: '20px', paddingX: '25px' }}>
                <Grid container item sx={{ justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: 24, fontFamily: 'Mulish', lineHeight: 1.3, fontWeight: 600 }}>Settings</Typography>

                    <IconButton
                        aria-label="close"
                        onClick={() => setSettingOpen(false)}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid container item >
                    <Grid container item sx={{ justifyContent: 'space-between', height: 39, color:'black' }} >
                        <Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Mulish', lineHeight: 1.3 }}>Feedback</Typography>
                        <Link href="mailto:playabbreviations@gmail.com" color={'#666666'}>
                            playabbreviations@gmail.com
                        </Link>
                    </Grid>
                    <Grid container item sx={{ justifyContent: 'space-between', height: 39 }}>
                        <Typography sx={{ fontSize: 16, fontWeight: 600, fontFamily: 'Mulish', lineHeight: 1.3, color:'black' }}>Report a Bug</Typography>
                        <Link href="mailto:playabbreviations@gmail.com" color={'#666666'}>
                            playabbreviations@gmail.com
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </CustomDialog>
    )
}