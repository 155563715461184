import React, { useEffect, useState } from "react";
import { Grid, Typography, Zoom, useScrollTrigger } from "@mui/material";

export default function AnswerItem({ title, intial, delay = 0 }) {
  const [isShow, setIsShow] = useState(false);
  const [isInitialShow, setIsInitialShow] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsInitialShow(false), 1000);
    setTimeout(() => setIsShow(true), 1500);
  }, [setIsShow, setIsInitialShow]);
  return (
    <Grid container item sx={{ flexDirection: "row", width: "fit-content" }}>
      <Grid item sx={{ display: !isShow ? "block" : "none" }}>
        <Zoom in={isInitialShow}>
          <Typography
            sx={{
              fontSize: {
                xs: "8.5vw",
                md: 44,
              },
              backgroundColor: "#F4F4F4",
              fontFamily: "Mulish",
              paddingX: "16px",
              paddingY: "8px",
              borderRadius: 4,
              fontWeight: 400,
              lineHeight: 1.3,
            }}
          >
            {intial}
          </Typography>
        </Zoom>
      </Grid>
      <Grid item sx={{ display: isShow ? "block" : "none" }}>
        <Zoom in={isShow}>
          <Typography
            sx={{
              fontSize: {
                xs: "8.5vw",
                md: 44,
              },
              backgroundColor: "#F4F4F4",
              fontFamily: "Mulish",
              paddingX: { md: "16px", xs: "3.5vw" },
              paddingY: "8px",
              borderRadius: 4,
              fontWeight: 400,
              lineHeight: 1.3,
            }}
          >
            {title}
          </Typography>
        </Zoom>
      </Grid>
    </Grid>
  );
}
