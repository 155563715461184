import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import CustomButton from "../components/CustomButton";
import CustomGrayButton from "../components/CustomGrayButton";
import { format, min } from "date-fns";

import LoadStorage from "../components/loadStorage";
import logo from "../assets/img/logo.png";

import adjust from "../components/adjust";

const Home = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:900px)");

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        alignItems: "center",
        paddingTop: { md: "182px", xs: "21.6vw" },
        rowGap: { md: 14, xs: "15.9vw" },
      }}
    >
      <LoadStorage />
      <Grid
        sx={{ justifyContent: "center", alignItems: "center" }}
        container
        item
      >
        <Typography
          sx={{
            fontSize: { md: "4rem", xs: "10vw" },
            fontFamily: "Mulish",
            fontWeight: { md: 600, xs: 500 },
          }}
        >
          Abbreviations
        </Typography>
      </Grid>

      <Grid
        item
        sx={{
          width: { md: 224, xs: "28vw" },
          height: { md: 224, xs: "28vw" },
        }}
      >
        <img
          src={logo}
          alt="home-logo"
          style={{ width: "100%", height: "100%" }}
        />
      </Grid>

      <Grid
        container
        item
        sx={{
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
          display: { md: "flex", xs: "none" },
        }}
      >
        <CustomGrayButton
          title={"How to play?"}
          onClick={() => navigate("/help")}
        />
        <CustomButton title={"Play"} onClick={() => navigate("/clue")} />
      </Grid>

      <Grid
        container
        item
        sx={{
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
          display: { md: "none", xs: "flex" },
          flexDirection: { sm: "column", xs: "row" },
        }}
      >
        <CustomButton title={"Play"} onClick={() => navigate("/clue")} />
        <CustomGrayButton
          title={"How to play?"}
          onClick={() => navigate("/help")}
        />
      </Grid>

      <Grid
        sx={{ justifyContent: "center", alignItems: "center", paddingBottom:{md:9, xs:'11vw'} }}
        container
        item
      >
        <Typography
          textAlign={"center"}
          sx={{
            fontFamily: "Mulish",
            width: "100%",
            fontWeight: 500,
            fontSize: { sm: 20, xs: '4.2vw' },
          }}
        >
          {format(new Date(), "MMMM dd, yyyy")}{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: { sm: 16, xs: '3.3vw' },
            color: "gray",
            fontFamily: "Mulish",
          }}
        >
          Edited by Nick Burns{" "}
        </Typography>
      </Grid>
    </Container>
  );
};

export default Home;
