import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import ReactCardFlip from "react-card-flip";

const InitialItem = ({ title, delay = 0, vibrations = false }) => {
  const [isFlipped, setISFlipped] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setISFlipped(false);
    }, delay);
  });
  return (
    <ReactCardFlip isFlipped={isFlipped}>
      <Grid
        item
        sx={{
          width: { md: 125, xs: '25vw' },
          height: { md: 125, xs: '25vw' },
          animation: vibrations ? "vibrate 0.5s ease-in-out" : "none",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: 84, xs: '17vw' },
            lineHeight: 1.3,
            fontFamily: "Mulish",
            backgroundColor: "#F4F4F4",
            paddingY: 1,
            textAlign: "center",
            borderRadius: 4,
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          width: { md: 125, xs: '25vw' },
          height: { md: 125, xs: '25vw' },
          borderRadius: 4,
          backgroundColor: "#c9c4c4",
        }}
      ></Grid>
    </ReactCardFlip>
  );
};

export default InitialItem;
