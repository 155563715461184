import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import checkIcon from "../assets/img/help-icon.png";
import CustomButton from "../components/CustomButton";
import logoImage from "../assets/img/logo.png";
import { Container, Grid, Typography } from "@mui/material";

import LoadStorage from "../components/loadStorage";
import adjust from "../components/adjust";

const ResponsiveImg = styled.img`
  width: 168px; // Default width for extra-small (xs) breakpoint
  height: 168px; // Default height for extra-small (xs) breakpoint

  @media (min-width: 600px) {
    width: 224px; // Width for small (sm) breakpoint
    height: 224px; // Height for small (sm) breakpoint
  }
`;

const Help = () => {
  const navigate = useNavigate();
  return (
    <Container
      sx={{
        display: "flex",
        flexWrap: "wrap",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "space-between",
        paddingTop: { md: "123px", xs: "18.6vw" },
        rowGap: { md: 24, xs: "30vw" },
      }}
    >
      <LoadStorage />

      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          maxWidth: { md: 583, xs: "85vw" },
        }}
      >
        <Grid
          container
          item
          sx={{ justifyContent: "center", rowGap: { md: 7, xs: "5.5vw" } }}
        >
          <Grid
            item
            sx={{
              width: { md: 128, xs: "17vw" },
              height: { md: 128, xs: "17vw" },
            }}
          >
            <img src={logoImage} style={{ width: "100%", height: "100%" }} onClick={() => navigate('/')} />
          </Grid>
          <Grid container item sx={{ rowGap: { md: 5, xs: "4.5vw" } }}>
            <Grid xs={12} container item sx={{ justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: { md: 44, xs: "7.4vw" },
                  fontFamily: "Mulish",
                  fontWeight: 500,
                }}
              >
                How To Play
              </Typography>
            </Grid>
            <Grid container item rowGap={3}>
              <Grid
                container
                item
                columnSpacing={2}
                sx={{
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Grid item >
                  <img
                    src={checkIcon}
                    style={{
                      width: 36,
                      height: 36,
                    }}
                    alt="checkicon"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: { md: 20, xs: "4.1vw" },
                      fontFamily: "Mulish",
                      color: "#54565B",
                    }}
                  >
                    Use the initials to come up with a group of words.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                columnSpacing={2}
                sx={{
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Grid item>
                  <img
                    src={checkIcon}
                    style={{
                      width: { sm: 36, xs: 24 },
                      height: { sm: 36, xs: 24 },
                    }}
                    alt="checkicon"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: { md: 20, xs: "4.1vw" },
                      fontFamily: "Mulish",
                      color: "#54565B",
                    }}
                  >
                    You are given 3 clues to try and guess the answer.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                columnSpacing={2}
                sx={{
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Grid item>
                  <img
                    src={checkIcon}
                    style={{
                      width: { sm: 36, xs: 24 },
                      height: { sm: 36, xs: 24 },
                    }}
                    alt="checkicon"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: { md: 20, xs: "4.1vw" },
                      fontFamily: "Mulish",
                      color: "#54565B",
                    }}
                  >
                    Try to get the answer as fast as you can, using the least
                    amount of clues.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                columnSpacing={2}
                sx={{
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Grid item>
                  <img
                    src={checkIcon}
                    sx={{
                      width: { sm: 36, xs: 24 },
                      height: { sm: 36, xs: 24 },
                    }}
                    alt="checkicon"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: { md: 20, xs: "4.1vw" },
                      fontFamily: "Mulish",
                      color: "#54565B",
                    }}
                  >
                    Articles and prepositions like “to”, “the” and “of” count as
                    a word in the group of initials.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        sx={{
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: { md: "90px", xs: "50px" },
        }}
      >
        <CustomButton title={"Play"} onClick={() => navigate("/clue")} />
      </Grid>
    </Container>
  );
};

export default Help;
