import * as React from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import MobileStepper, {
  mobileStepperClasses,
} from "@mui/material/MobileStepper";

const CustomMobileStepper = styled(MobileStepper)(({ theme }) => ({
  [`& .${mobileStepperClasses.dot}`]: {
    backgroundColor: "#E2E2E2",
    width: 7,
    height: 7,
    borderRadius: 24,
    transition: "width 0.3s ease-in-out", // Adding CSS transition for background-color
  },

  [`& .${mobileStepperClasses.dotActive}`]: {
    backgroundColor: "black",
    width: 18.45,
    height: 7,
    borderRadius: 24,
    transition: "width 0.3s cubic-bezier(0.77, 0, 0.175, 1)", // Adding CSS transition for background-color
  },
}));

export default function DotsMobileStepper({steps, currentStep}) {

  return (
    <Grid  item>
      <CustomMobileStepper
        variant="dots"
        steps={steps}
        position="static"
        activeStep={currentStep }
        sx={{ maxWidth: 400, flexGrow: 1 }}
      />
    </Grid>
  );
}
