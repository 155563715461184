import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";

const LoadStorage = ({ clueId = 1 }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    console.log(pathname);
    const abbreviation = JSON.parse(localStorage.getItem("abbreviation"));
    if (abbreviation) {
      const lastDate = abbreviation["lastDate"];
      if (format(new Date(), "yyyy-MM-dd") <= lastDate) {
        navigate("/result");
      } else {
        if (pathname === "/result") {
          navigate("/");
        }
      }
    } else {
      if (pathname === "/result") {
        navigate("/");
      }
    }
  }, [pathname, clueId, navigate]);

  // useEffect(() => {
  //   const handleActivity = () => {
  //     setIsActive(true);
  //     console.log("sdfsd");
  //   };

  //   // Event listeners for various user interactions
  //   window.addEventListener("click", handleActivity);
  //   window.addEventListener("mousemove", handleActivity);
  //   window.addEventListener("keydown", handleActivity);

  //   // Cleanup function to remove event listeners
  //   return () => {
  //     window.removeEventListener("click", handleActivity);
  //     window.removeEventListener("mousemove", handleActivity);
  //     window.removeEventListener("keydown", handleActivity);
  //   };
  // }, []);

  // useEffect(() => {
  //   let timeoutId;

  //   timeoutId = setTimeout(() => {
  //     navigate("/");
  //   }, 6000);

  //   // Cleanup function to clear the timeout
  //   return () => clearTimeout(timeoutId);
  // }, [isActive, navigate]);

  return null;
};
export default LoadStorage;
