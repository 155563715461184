import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
} from "react";
import {
  Container,
  Grid,
  Typography,
  Slide,
  Fade,
  Snackbar,
  Alert,
  styled,
  Dialog,
  dialogClasses,
  useMediaQuery,
} from "@mui/material";

import MD3Button from "@mui/material-next/Button";
import axios from "axios";
import ReactCardFlip from "react-card-flip";
import { format } from "date-fns";
import html2canvas from "html2canvas";

import AdSense from "react-adsense";

import VectorIcon from "../assets/img/Vector.png";

import AnswerItem from "../components/AnswerItem";
import AnsClueItem from "../components/AnsClueItem";
import LoadStorage from "../components/loadStorage";
import LifetimeComponent from "../components/LifetimeComponent";
import CongratHeader from "../components/CongratHeader";
import logo50 from "../assets/img/logo_50_.png";

const API_URL = process.env.REACT_APP_API_URL;

const CustomDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    borderRadius: "18px",
    width: 280,
    height: 280,
  },
}));

const Trasition = forwardRef(function Trasition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Congrats = () => {
  const [isResult, setIsResult] = useState(false);
  const [status, setStatus] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [initials, setInitials] = useState("");
  const [clue1, setClue1] = useState("");
  const [clue2, setClue2] = useState("");
  const [clue3, setClue3] = useState("");
  const [fails, setFails] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [clues, setClues] = useState(0);
  const [successes, setSuccesses] = useState(0);
  const [rendered, setRendered] = useState(false);
  const [isCongrats, setIsCongrats] = useState(false);
  const [isShowMessage, setShowMessage] = useState(false);
  const [isShare, setShare] = useState(false);
  const isDesktop = useMediaQuery("(min-width:900px)");

  const imageRef = useRef(null);
  const shareRef = useRef(null);

  useEffect(() => {
    if (!rendered) setRendered(true);
    setTimeout(() => setIsCongrats(true));
  }, [rendered, setRendered]);

  useEffect(() => {
    const abbreviation = JSON.parse(localStorage.getItem("abbreviation"));
    if (abbreviation) {
      setStatus(abbreviation["status"]);
      setFails(abbreviation["fails"]);
      setSuccesses(abbreviation["success"]);
      setTotalTime(abbreviation["totalTime"]);
      setClues(abbreviation["clues"]);
    }
    const fetchData = async () => {
      try {
        const response = await axios.post(`${API_URL}/data`, {
          formattedDate: format(new Date(), "yyyy-MM-dd"),
        });
        setAnswer(response.data.data.answer.split(" "));
        setClue1(response.data.data.firClue);
        setClue2(response.data.data.secClue);
        setClue3(response.data.data.thrClue);
        setInitials(response.data.data.initials);

        return response;
      } catch (err) {}
    };
    fetchData();
  }, [
    setStatus,
    setFails,
    setSuccesses,
    setAnswer,
    setClue1,
    setClue2,
    setClue3,
  ]);

  const formatTotaltime = (time) => {
    if (time < 60) return time;
    if (time % 60 < 10) return `${parseInt(time / 60)}:0${parseInt(time % 60)}`;
    return `${parseInt(time / 60)}:${parseInt(time % 60)}`;
  };

  // useEffect(() => {
  //   try {
  //     (window.adsbygoogle = window.adsbygoogle || []).push({});
  //   } catch (e) {}
  // }, []);

  const shareHandle = async () => {
    const fetchData = async () => {
      try {
        const canvas = await html2canvas(imageRef.current);
        const imageData = canvas.toDataURL("image/png");
        console.log(typeof imageData);

        const response = await axios.post(`${API_URL}/getshareId`, {
          totalTime: totalTime,
          totalClues: clues,
          dates: format(new Date(), "EEEE, MMM dd, yyyy"),
          base64Image: imageData,
        });
        console.log(response.data["shareString"]);

        return response.data["shareString"];
      } catch (err) {}
    };
    const shareString = await fetchData();

    if (navigator.share) {
      navigator
        .share({
          title: "Share your score",
          url: `https://playabbreviations.com/api/share/${shareString}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
    }

    // setTimeout(() => {
    //   toPng(shareRef.current, { cacheBust: true })
    //     .then((dataUrl) => {
    //       const binaryString = window.atob(dataUrl.split("base64,")[1]);

    //       const binaryLen = binaryString.length;
    //       const bytes = new Uint8Array(binaryLen);
    //       for (let i = 0; i < binaryLen; i++) {
    //         bytes[i] = binaryString.charCodeAt(i);
    //       }

    //       const blob = new Blob([bytes], { type: "image/png" });

    //       const img = document.createElement("img");
    //       img.src = URL.createObjectURL(blob);

    //       if (navigator.clipboard) {
    //         navigator.clipboard
    //           .write([new ClipboardItem({ "image/png": blob })])
    //           .then(() => {
    //             console.log("Image copied to clipboard!");
    //             setShowMessage(true);
    //           })
    //           .catch(() => {
    //             console.error("Failed to copy image to clipboard.");
    //           });
    //       } else {
    //         console.error("Clipboard API not supported.");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }, 1500);
  };

  const countDelay = (index) => {
    let sum = 0;
    for (let i = 0; i < index; i++) sum += answer[i].length;
    return sum - index;
  };

  return (
    <>
      {/* {rendered && isDesktop && (
        // <Grid container item sx={{ width: "100%", height: 250 }}>
        //   <ins
        //     className="adsbygoogle"
        //     style={{ display: "block" }}
        //     data-ad-client="ca-pub-5555692085574234"
        //     data-ad-slot="8577496386"
        //     data-ad-format="auto"
        //     data-full-width-responsive="true"
        //   ></ins>
        // </Grid>
      )} */}
      <Container
        sx={{
          display: "flex",
          height: "calc(100vh)",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "space-between",
          paddingBottom: "50px",
          paddingTop: { md: 11, xs: "13.6vw" },
          rowGap: { md: "40px", xs: "5vw" },
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={isShowMessage}
          autoHideDuration={6000}
          onClose={() => setShowMessage(false)}
        >
          <Alert
            onClose={() => setShowMessage(false)}
            variant="filled"
            severity="success"
            sx={{ width: "100%", backgroundColor: "black", color: "white" }}
          >
            Copied to clipboard!
          </Alert>
        </Snackbar>

        <LoadStorage />

        <CongratHeader status={status} isCongrats={isCongrats} />

        <Grid
          container
          item
          sx={{
            justifyContent: "center",
            alignItems: "center",
            gap: { md: 1, xs: "1.8vw" },
          }}
        >
          {answer.map((item, index) => (
            <AnswerItem
              key={index}
              title={item}
              intial={initials[index]}
              delay={200 * countDelay(index)}
            />
          ))}
        </Grid>

        <Grid
          container
          item
          sx={{
            flexDirection: "column",
            width: "fit-content",
            rowGap: { md: 1, xs: "1.5vw" },
          }}
        >
          <AnsClueItem
            clueId={1}
            clue={clue1}
            isShow={isCongrats}
            direction={"left"}
          />
          <AnsClueItem
            clueId={2}
            clue={clue2}
            isShow={isCongrats}
            direction={"right"}
          />
          <AnsClueItem
            clueId={3}
            clue={clue3}
            isShow={isCongrats}
            direction={"left"}
          />
        </Grid>

        {status ? (
          <Fade
            timeout={2000}
            in={isCongrats}
            style={{ transitionDelay: isCongrats ? "3000ms" : "0ms" }}
          >
            <Grid
              container
              item
              sx={{
                flexDirection: "column",
                paddingX: { xs: 5, md: 20 },
              }}
            >
              <Typography
                fontWeight={300}
                fontFamily={"Mulish"}
                lineHeight={1.3}
                sx={{
                  fontSize: { md: 24, xs: "4.65vw" },
                  textAlign: "center",
                }}
              >
                I solved <b style={{ fontWeight: 500 }}>Abbreviations</b> in{" "}
                <b style={{ fontWeight: 500 }}>{formatTotaltime(totalTime)}</b>{" "}
                {totalTime >= 60 ? " " : "seconds "}
                using <b style={{ fontWeight: 500 }}>{clues}</b>{" "}
                {clues === 1 ? "clue" : "clues"}.
              </Typography>
            </Grid>
          </Fade>
        ) : (
          <Grid
            container
            item
            sx={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-5px",
              height: 26,
            }}
          ></Grid>
        )}

        <Fade
          in={isCongrats}
          timeout={2000}
          style={{ transitionDelay: isCongrats ? "4000ms" : "0ms" }}
        >
          <Grid
            container
            item
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            rowGap={2}
          >
            <Grid item>
              <Typography
                fontFamily={"Mulish"}
                lineHeight={1.3}
                textAlign={"center"}
                sx={{ fontSize: { md: 22, xs: "5vw" } }}
              >
                {status ? "See? That wasn't so hard." : "You got the next one."}
              </Typography>
            </Grid>
            {status ? (
              <Grid container item sx={{ justifyContent: "center" }}>
                <MD3Button
                  sx={{
                    background: "black",
                    fontFamily: "Mulish",
                    color: "white",
                    "&:hover": { background: "black" },
                    width: { md: 72, xs: "16.7vw" },
                    height: 48,
                  }}
                  onClick={shareHandle}
                >
                  <img src={VectorIcon} alt="vector" />
                </MD3Button>
              </Grid>
            ) : (
              <Grid item sx={{ height: 48, width: "100%" }}></Grid>
            )}
          </Grid>
        </Fade>

        <Fade
          in={isCongrats}
          timeout={2000}
          style={{ transitionDelay: isCongrats ? "4500ms" : "0ms" }}
        >
          <Grid
            container
            item
            rowGap={2}
            sx={{
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ReactCardFlip isFlipped={isResult}>
              <Grid container item>
                <MD3Button
                  sx={{
                    fontFamily: "Mulish",
                    backgroundColor: "#F1F2F4",
                    color: "black",
                    fontSize: { md: 24, xs: "5.5vw" },
                    fontWeight: 500,
                    width: { md: 335, xs: "69.7vw" },
                    height: { md: 60, xs: "13vw" },
                  }}
                  onClick={() => setIsResult(true)}
                >
                  See your results
                </MD3Button>
              </Grid>
              <LifetimeComponent successes={successes} fails={fails} />
            </ReactCardFlip>
          </Grid>
        </Fade>
        <Grid
          container
          item
          sx={{
            rowGap: "8px",
            paddingTop: "20px",
            paddingX: "25px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            ref={imageRef}
            container
            item
            sx={{
              width: 588,
              height: 402,
              borderRadius: 5,
              backgroundColor: "#EEF3F8",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              position: "absolute",
              top: "-1200px",
            }}
          >
            <Grid
              container
              item
              sx={{
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 4,
                paddingX: 15,
              }}
            >
              <Grid item sx={{ width: 40, height: 40 }}>
                <img src={logo50} style={{ width: "100%", height: "100%" }} />
              </Grid>
              <Grid item width={"100%"}>
                <Typography
                  sx={{
                    fontSize: 36,
                    fontWeight: 500,
                    fontFamily: "Mulish",
                    textAlign: "center",
                    lineHeight: 1.3,
                    paddingTop: 1,
                  }}
                >
                  Abbreviations
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 300,
                    fontFamily: "Mulish",
                    textAlign: "center",
                    lineHeight: 1.3,
                  }}
                >
                  I solved <b style={{ fontWeight: 600 }}>Abbreviations</b> in{" "}
                  <b style={{ fontWeight: 600 }}>
                    {formatTotaltime(totalTime)}
                  </b>{" "}
                  {totalTime >= 60 ? " " : "seconds "}
                  using <b style={{ fontWeight: 600 }}>{clues}</b>{" "}
                  {clues === 1 ? "clue" : "clues"}.
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 400,
                    fontFamily: "Mulish",
                    textAlign: "center",
                    lineHeight: 1.3,
                    paddingTop: 1.5,
                    color: "#767676",
                  }}
                >
                  {format(new Date(), "EEEE, MMM dd, yyyy")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              sx={{
                height: 152,
                backgroundColor: "#000",
                position: "relative",
              }}
            >
              <Grid
                container
                item
                sx={{
                  gap: "6px",
                  height: 41,
                  position: "absolute",
                  top: "15px",
                  left: "30px",
                }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 36,
                      fontWeight: 500,
                      fontFamily: "Mulish",
                      color: "white",
                    }}
                  >
                    Play Abbreviations
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 400,
                      fontFamily: "Mulish",
                      color: "white",
                    }}
                  >
                    www.playabbreviations.com
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Congrats;
