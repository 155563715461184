import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
  Snackbar,
  Alert,
  useMediaQuery,
  bottomNavigationClasses,
} from "@mui/material";
import axios from "axios";
import AdSense from "react-adsense";

import InitialItem from "../components/InitialItem";
import PagenationComponents from "../components/PagenationComponent";
import ClueHeader from "../components/ClueHeader";
import CustomInput from "../components/CustomInput";
import CustomButton from "../components/CustomButton";
import CustomGrayButton from "../components/CustomGrayButton";
import SettingDrawer from "../components/SettingDrawer";
import SettingDialog from "../components/SettingDialog";

import LoadStorage from "../components/loadStorage";
import { format } from "date-fns";

const API_URL = process.env.REACT_APP_API_URL;

const Clue = () => {
  const [initials, setInitials] = useState("");
  const [clueText, setClueText] = useState("");
  const [clueId, setClueId] = useState(1);
  const [totalTime, setTotalTime] = useState(0);
  const [settingOpen, setSettingOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [vibrations, setVibrations] = useState(false);
  const [isShowMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [rendered, setRendered] = useState(false);

  const formRef = useRef(null);
  const banner = useRef(null);

  const isDesktop = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();

  const atOptions = {
    key: "8d0d9173aca9f5c65aaae6e50ae7f82d",
    format: "iframe",
    height: 90,
    width: 728,
    params: {},
  };
  useEffect(() => {
    const fetchScript = async () => {
      try {
        const config = {
          method: "get",
          url: "https://pl23139059.highcpmgate.com/4edaa01860677cc5e0c6ad911be47dd6/invoke.js",
          headers: {
            "X-API-KEY": "8fbce10a3fc58c317ac8df98977d57fc",
          },
        };
        const response = await axios(config);
        
        const scriptText = response.data;
        const scriptElement = document.createElement("script");
        scriptElement.textContent = scriptText;
        document.body.appendChild(scriptElement);
      } catch (error) {
        console.error("Error fetching script:", error);
      }
    };
    fetchScript();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(API_URL, {
          clueId: clueId,
          formattedDate: format(new Date(), "yyyy-MM-dd"),
        });

        setInitials(response.data["dataInitial"]);
        setClueText(response.data["clueTitle"]);
        return response;
      } catch (err) {}
    };
    fetchData();
  }, [clueId]);

  useEffect(() => {
    if (!rendered) setRendered(true);
  }, [setRendered, rendered]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalTime(totalTime + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [totalTime]);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   try {
  //     (window.adsbygoogle = window.adsbygoogle || []).push({});
  //   } catch (e) {}
  // }, []);

  const setttingClick = () => {
    if (screenWidth > 700) setSettingOpen(true);
    else setDrawerOpen(true);
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
    const formData = new FormData(e);
    const jsonData = Object.fromEntries(formData.entries());
    console.log(jsonData);

    const myanses = [];
    for (let key in jsonData) myanses.push(jsonData[key].trim());

    const response = await axios.post(`${API_URL}/check`, {
      answer: myanses,
      formattedDate: format(new Date(), "yyyy-MM-dd"),
    });
    let abbreviation = localStorage.getItem("abbreviation");

    if (response.data["result"]) {
      if (abbreviation) {
        abbreviation = JSON.parse(abbreviation);
        abbreviation["lastDate"] = format(new Date(), "yyyy-MM-dd");
        abbreviation["success"] = parseInt(abbreviation["success"]) + 1;
        abbreviation["status"] = true;
        abbreviation["clues"] = clueId;
        abbreviation["totalTime"] = totalTime;

        localStorage.setItem("abbreviation", JSON.stringify(abbreviation));
      } else {
        abbreviation = {};
        abbreviation["lastDate"] = format(new Date(), "yyyy-MM-dd");
        abbreviation["fails"] = 0;
        abbreviation["success"] = 1;
        abbreviation["status"] = true;
        abbreviation["totalTime"] = totalTime;
        abbreviation["clues"] = clueId;
        localStorage.setItem("abbreviation", JSON.stringify(abbreviation));
      }

      navigate("/result");
    } else {
      setVibrations(true);
      setTimeout(() => setVibrations(false), 1000);
      setShowMessage(true);
      setMessage(`${response.data["count"]} wrong answers`);

      for (let key in jsonData) e[key].value = "";
    }
  };

  const handleNext = () => {
    if (clueId === 3) {
      let abbreviation = localStorage.getItem("abbreviation");

      if (abbreviation) {
        abbreviation = JSON.parse(abbreviation);
        abbreviation["lastDate"] = format(new Date(), "yyyy-MM-dd");
        abbreviation["fails"] = parseInt(abbreviation["fails"]) + 1;
        abbreviation["status"] = false;
        abbreviation["totalTime"] = totalTime;
        abbreviation["clues"] = clueId;
        localStorage.setItem("abbreviation", JSON.stringify(abbreviation));
      } else {
        abbreviation = {};
        abbreviation["lastDate"] = format(new Date(), "yyyy-MM-dd");
        abbreviation["fails"] = 1;
        abbreviation["success"] = 0;
        abbreviation["status"] = false;
        abbreviation["totalTime"] = totalTime;
        abbreviation["clues"] = clueId;
        localStorage.setItem("abbreviation", JSON.stringify(abbreviation));
      }

      navigate("/result");
    } else {
      setClueId(clueId + 1);
    }
  };

  return (
    <>
      <Grid container item sx={{ width: "100%", height: 250 }}>
        {/* <ins
            className="adsbygoogle"
            style={{display:'block'}}
            data-ad-client="ca-pub-5555692085574234"
            data-ad-slot="8577496386"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins> */}
        {/* <AdSense.Google
            client="ca-pub-5555692085574234"
            slot="8577496386"
            style={{ display: "block", width:'100%' , height:250 }}
            format="auto"
            responsive="true"
          /> */}
        <div id="container-4edaa01860677cc5e0c6ad911be47dd6"></div>
      </Grid>

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "20px",
          height: "100vh",
          rowGap: { md: "90px", xs: "7.9vw" },
        }}
      >
        <LoadStorage clueId={clueId} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={isShowMessage}
          autoHideDuration={6000}
          onClose={() => setShowMessage(false)}
        >
          <Alert
            onClose={() => setShowMessage(false)}
            variant="filled"
            severity="error"
            sx={{ width: "100%", backgroundColor: "black", color: "white" }}
          >
            {message}
          </Alert>
        </Snackbar>
        <ClueHeader totalTime={totalTime} onClick={setttingClick} />

        <Grid
          container
          item
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Grid item>
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontSize: { md: 28, xs: "5.5vw" },
                lineHeight: 1.3,
                fontWeight: 600,
              }}
            >
              Clue {clueId}
            </Typography>
            <Box
              sx={{
                borderColor: "#ED1967",
                borderWidth: 0.5,
                borderStyle: "solid",
                marginX: 1.5,
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontFamily: "Mulish",
                fontSize: { md: 24, xs: "4.65vw" },
                lineHeight: 1.3,
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              {clueText}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          sx={{
            gap: "2.7vw",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {initials.split("").map((item, index) => (
            <InitialItem
              key={index}
              title={item}
              delay={500 * (index + 1)}
              vibrations={vibrations}
            />
          ))}
        </Grid>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "60px",
          }}
        >
          <Grid
            container
            item
            rowGap={3}
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
            }}
          >
            {initials.split("").map((item, index) => (
              <Grid
                container
                item
                sx={{ justifyContent: "center" }}
                key={index + item}
              >
                <CustomInput name={"myans" + index} />
              </Grid>
            ))}
            <Grid container item sx={{ justifyContent: "center" }}>
              <PagenationComponents steps={3} currentStep={clueId - 1} />
            </Grid>
          </Grid>
        </form>
        <Grid
          container
          item
          sx={{
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
            display: { md: "flex", xs: "none" },
            paddingBottom: { md: "80px", xs: "50px" },
          }}
        >
          <CustomGrayButton
            title={clueId === 3 ? "Give Up" : "Next clue"}
            onClick={handleNext}
          />
          <CustomButton
            title={"Submit"}
            form={"formAnswer"}
            onClick={() => {
              handleSubmit(formRef.current);
            }}
          />
        </Grid>

        <Grid
          container
          item
          sx={{
            gap: 2,
            justifyContent: "center",
            alignItems: "center",
            display: { md: "none", xs: "flex" },
            flexDirection: { sm: "column", xs: "row" },
            paddingBottom: { md: "80px", xs: "50px" },
          }}
        >
          <CustomButton
            title={"Submit"}
            form={"formAnswer"}
            onClick={() => {
              handleSubmit(formRef.current);
            }}
          />
          <CustomGrayButton
            title={clueId === 3 ? "Give Up" : "Next clue"}
            onClick={handleNext}
          />
        </Grid>
        <SettingDialog
          settingOpen={settingOpen}
          setSettingOpen={() => setSettingOpen(false)}
        />
        <SettingDrawer
          drawerOpen={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        />
      </Container>
    </>
  );
};

export default Clue;
