import React from "react";
import { Grid, Input } from "@mui/material";

export default function CustomInput ({name}) {
  return (
    <Grid item>
      <Input
        id={"input" + name}
        type="text"
        name={name}
        autoComplete="off"
        placeholder="Your Word"
        sx={{
          backgroundColor: "#F4F4F4",
          width: {md:366, xs: ' 70vw'},
          height: {md:50, xs:'10vw'},
          borderRadius: "10px",
          borderBottom: "none",
          paddingY: "10px",
          paddingLeft: "10px",
          paddingRight: "12px",
          fontFamily: "Mulish",
          fontSize: {md:20, xs:'4.1vw'},
          color: "black",
          "&::before": {
            content: "none",
          },
          "&::after": {
            content: "none",
          },
        }}
      />
    </Grid>
  );
}
