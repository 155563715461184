import React, { useEffect, useState } from "react";
import { Grid, Fade, Typography } from "@mui/material";
import CongratsImage from "../assets/img/congrats-image.png";
import FailImage from "../assets/img/fail-image.png";

export default function CongratHeader({ isCongrats, status }) {
  const [isDisply, setIsDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsDisplay(true), 2000);
  }, [setIsDisplay]);
  return (
    <Fade
      in={isCongrats}
      timeout={2000}
      style={{ transitionDelay: isCongrats ? "2000ms" : "0ms" }}
    >
      <Grid
        container
        item
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          visibility: isDisply ? "visible" : "hidden",
          rowGap: { md: 3, xs: "5vw" },
          flexDirection:'column'
        }}
      >
        <Grid
          item
          justifyContent={"center"}
          sx={{
            height: { md: 88, xs: "17vw" },
            width: { md: 88, xs: "17vw" },
          }}
        >
          <img
            src={status ? CongratsImage : FailImage}
            alt="contgrats"
            style={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid item>
          <Typography
            fontFamily={"Mulish"}
            fontWeight={500}
            lineHeight={1.25}
            sx={{
              fontSize: { md: 40, xs: "7.4vw" },
            }}
            textAlign={"center"}
          >
            {status ? "Congratulations!" : "Better luck next time"}
          </Typography>
        </Grid>
      </Grid>
    </Fade>
  );
}
