import React from "react";
import MD3Button from "@mui/material-next/Button";
import adjust from "./adjust";

export default function CustomGrayButton({ title, onClick }) {
  return (
    <MD3Button
      variant="filled"
      sx={{
        color: "black",
        width: { md: 335, xs: '70vw' },
        height: { md: 60, xs: '11.6vw' },
        background: "#F1F2F4",
        fontSize: { md : 20, xs: '4.1vw' },
        fontWeight: 400,
        fontFamily: "Mulish",
        "&:hover": {
          background: "#F1F2F4",
          boxShadow: "none",
        },
      }}
      onClick={onClick}
    >
      {title}
    </MD3Button>
  );
}
