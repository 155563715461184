import React from "react";
import { Grid, Typography } from "@mui/material";

export default function LifetimeComponent({ successes, fails }) {
  return (
    <Grid
      container
      item
      rowGap={3}
      sx={{
        flexDirection: "column",
        background: "#F4F6FA",
        paddingX: 3,
        paddingY: 2,
        borderRadius: 6,
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        width: "auto",
      }}
    >
      <Grid item>
        <Typography
          fontWeight={500}
          lineHeight={1.3}
          fontFamily={"Mulish"}
          textAlign={"center"}
          sx={{ fontSize: { md: 20, xs: "4.65vw" } }}
        >
          Your Lifetime Score
        </Typography>
      </Grid>
      <Grid container item>
        <Grid
          container
          item
          flexDirection={"row"}
          justifyContent={"center"}
          sx={{
            columnGap: { md: 10, xs: "15vw" },
          }}
        >
          <Grid
            container
            item
            flexDirection={"column"}
            sx={{ width: "fit-content" }}
          >
            <Grid item>
              <Typography
                lineHeight={1.5}
                fontWeight={400}
                color={"#828282"}
                sx={{ fontSize: { md: 16, xs: "3.7vw" } }}
              >
                Pass
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontWeight={500}
                lineHeight={1.3}
                fontFamily={"Mulish"}
                textAlign={"center"}
                sx={{ fontSize: { md: 20, xs: "4.5vw" } }}
              >
                {successes}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            flexDirection={"column"}
            sx={{ width: "fit-content" }}
          >
            <Grid item>
              <Typography
                sx={{ fontSize: { md: 16, xs: "3.7vw" } }}
                lineHeight={1.5}
                fontWeight={400}
                color={"#828282"}
              >
                Fail
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontWeight={500}
                lineHeight={1.3}
                fontFamily={"Mulish"}
                textAlign={"center"}
                sx={{ fontSize: { md: 20, xs: "4.5vw" } }}
              >
                {fails}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            flexDirection={"column"}
            sx={{ width: "fit-content" }}
          >
            <Grid item>
              <Typography
                sx={{ fontSize: { md: 16, xs: "3.7vw" } }}
                lineHeight={1.5}
                fontWeight={400}
                color={"#828282"}
              >
                Score
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontWeight={500}
                sx={{ fontSize: { md: 20, xs: "4.5vw" } }}
                lineHeight={1.3}
                fontFamily={"Mulish"}
                textAlign={"center"}
              >
                {successes - fails}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
