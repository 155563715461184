import React from "react";
import { Drawer, drawerClasses, Grid, IconButton, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { fontSize } from "@mui/system";

const CustomDrawer = styled(Drawer)(({ theme }) => ({
    [`& .${drawerClasses.paperAnchorBottom}`]: {
        borderRadius: '18px 18px 0px 0px',
        height:232
    }
}))

export default function SettingDrawer ({drawerOpen, onClose}) {
    return (
        <CustomDrawer
            anchor={'bottom'}
            open={drawerOpen}
            onClose={onClose}
        >
            <Grid container item sx={{ rowGap: '40px', paddingTop: '20px', paddingX: '5.81vw' }}>
                <Grid container item sx={{ justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: {xs:'5.58vw'}, fontFamily: 'Mulish', lineHeight: 1.3, fontWeight: 500 }}>Settings</Typography>

                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid container item >
                    <Grid container item sx={{ justifyContent: 'space-between', height: 39 }} >
                        <Typography sx={{ fontSize: '3.72vw', fontWeight: 500, fontFamily: 'Mulish', lineHeight: 1.3 , color:"black"}}>Feedback</Typography>
                        <Link href="mailto:playabbreviations@gmail.com" color={'#666666'} fontSize='3.25vw'>
                            playabbreviations@gmail.com
                        </Link>
                    </Grid>
                    <Grid container item sx={{ justifyContent: 'space-between', height: 39 }}>
                        <Typography sx={{ fontSize: '3.72vw', fontWeight: 500, fontFamily: 'Mulish', lineHeight: 1.3 }}>Report a Bug</Typography>
                        <Link href="mailto:playabbreviations@gmail.com"  color={'#666666'}  fontSize='3.25vw'>
                            playabbreviations@gmail.com
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}