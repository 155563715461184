import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Home from "./pages/home";
import Help from "./pages/help";
import Congrats from "./pages/congrats";
import Clue from "./pages/clue";
import Share from "./pages/share";
import MobileAd from "./pages/mobileAd";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/help" element={<Help />} />
        <Route path="/result" element={<Congrats />} />
        <Route path="/clue" element={<Clue />} />
        <Route path="/share" element = {<Share/>} />
        <Route path="/ready" element = {<MobileAd />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
