import React, { useEffect, useState } from "react";
import { Grid, Typography, Fade } from "@mui/material";

export default function AnsClueItem({ clueId, clue, isShow, direction }) {
  const [isDisply, setIsDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsDisplay(true), 2000);
  }, [setIsDisplay]);
  return (
    <Fade
      in={isShow}
      timeout={2000}
      style={{
        transitionDelay: isShow ? "3000ms" : "0ms",
        visibility: isDisply ? "visible" : "hidden",
      }}
    >
      <Grid
        container
        item
        sx={{
          justifyContent: "start",
          alignItems: "center",
          width: "fit-content",
          columnGap: { md: 1, xs: "1.86vw" },
        }}
      >
        <Grid item>
          <Typography
            fontWeight={500}
            fontFamily={"Mulish"}
            lineHeight={1.3}
            sx={{
              fontSize: { md: 26, xs: "5.5vw" },
            }}
          >
            Clue {clueId}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontWeight={400}
            fontFamily={"Mulish"}
            lineHeight={1.3}
            sx={{
              fontSize: { md: 22, xs: "4.65vw" },
            }}
          >
            {clue}
          </Typography>
        </Grid>
      </Grid>
    </Fade>
  );
}
