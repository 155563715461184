import React, { useEffect, useState } from "react";
import { Grid,  Typography } from "@mui/material";
import logoImage from "../assets/img/logo_50.png";
import settingImage from "../assets/img/settings.png";
import { useNavigate } from "react-router-dom";

const ClueHeader = ({totalTime, onClick}) => {
  const [formattedTime, setFormattedTime] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    var minutes = Math.floor(totalTime / 60);
    var seconds = (totalTime % 60)
    if(seconds < 10)  seconds = "0" + seconds ;
    setFormattedTime(minutes + ":" + seconds)
  }, [setFormattedTime, totalTime])
  return (
    <Grid
      container sx={{justifyContent:'space-between', alignItems:'center'}}
    >
      <Grid item  sx={{ width: 35, height: 35 }}>
        <img src={logoImage} alt="logo" style={{ width: '100%', height: '100%' }} onClick={() => navigate('/')}/>
      </Grid>
      <Grid item >
        <Typography sx={{ fontSize: {md: 25 ,xs:'6vw'}, lineHeight:1.3,fontWeight: 500, fontFamily: "Mulish" , paddingTop:'10px'}}>
          {formattedTime}
        </Typography>
      </Grid>
      <Grid item sx={{width:23, height:23}}>
        <img
          src={settingImage}
          alt="setting"
          onClick={onClick}
          style={{ cursor: "pointer", width:'100%', height:'100%' }}
        />
      </Grid>
    </Grid>
  );
};

export default ClueHeader;
