import React from "react";
import MD3Button from "@mui/material-next/Button";
import adjust from "./adjust";

export default function CustomButton({ title, onClick }) {
  return (
    <MD3Button
      variant="filled"
      onClick={onClick}
      type="submit"
      sx={{
        background: "black",
        width: { md: 335, xs: '70vw' },
        height: { md: 60, xs: '11.6vw' },
        fontWeight: 500,
        fontFamily: "Mulish",
        fontSize: { md: 20, xs: '4.1vw' },
        "&:hover": {
          background: "black",
        },
      }}
    >
      {title}
    </MD3Button>
  );
}
