import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import html2canvas from "html2canvas";
import axios from "axios";
import { useLocation } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

// Use a functional component with controlled re-rendering:
const Share = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const imageRef = useRef(null);
  const metaTagRef = useRef(null);
  const [shouldRender, setShouldRender] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [totalClues, setTotalClues] = useState(0);
  const [gameDate, setGameDate] = useState("");
  const [metaimage, setMetaImage] = useState("");

  // Add error handling to data-fetching effect:
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/share/${searchParams.get("shareid")}`
        );
        setTotalTime(response.data["totalTime"]);
        setTotalClues(response.data["totalClues"]);
        setGameDate(response.data["gameDate"]);
      } catch (err) {
        console.error("Error fetching data:", err);
        // Handle errors gracefully (e.g., display a user-friendly message)
      }
    };
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    const changeMetaImage = async () => {
      try {
        imageRef.current.focus()
        const canvas = await html2canvas(imageRef.current);
        const imageData = canvas.toDataURL("image/png");
        // metaTagRef.current.content = imageData;
        // document.getElementById("sharing-og-image").content = imageData;
        console.log(imageData)
        setShouldRender(true);
      } catch (error) {
        console.error("Error capturing image:", error);
      }
    };
    changeMetaImage();
  }); 

  return (
    <Grid
      container
      item
      sx={{
        rowGap: "8px",
        paddingTop: "20px",
        paddingX: "25px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <meta property="og:image" content={metaimage} ref={metaTagRef} />
      </Helmet>
      <Grid
        ref={imageRef}
        container
        item
        sx={{
          width: 588,
          height: 402,
          borderRadius: 5,
          backgroundColor: "#EEF3F8",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          position:'absolute',
          top:'-1200px'
        }}
      >
        <Grid
          container
          item
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop:5,
            paddingX:15
          }}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: 36,
                fontWeight: 500,
                fontFamily: "Mulish",
                textAlign: "center",
              }}
            >
              Abbreviations
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: 24,
                fontWeight: 400,
                fontFamily: "Mulish",
                textAlign: "center",
                paddingTop:1
              }}
            >
              I solved Abbreviations in {totalTime} seconds using {totalClues}{" "}
              {totalClues === 1 ? "clue" : "clues"}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 400,
                fontFamily: "Mulish",
                textAlign: "center",
                paddingTop:2
              }}
            >
              {gameDate}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sx={{
            height: 152,
            borderRadius: 5,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: "#000",
            position: "relative",
          }}
        >
          <Grid
            container
            item
            sx={{
              gap: "6px",
              height: 41,
              position: "absolute",
              top: "15px",
              left: "30px",
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: 36,
                  fontWeight: 500,
                  fontFamily: "Mulish",
                  color: "white",
                }}
              >
                Play Abbreviations
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 400,
                  fontFamily: "Mulish",
                  color: "white",
                }}
              >
                www.playabbreviations.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Share;
